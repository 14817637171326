import { Api } from "../../api";
import { BadRequest, Reset } from "../../api/reset";
import { loader } from "../../comp/loader";
import { Router } from "../../route";
import { alreadyUsedViewFactory } from "./alreadyUsed";
import { expiredViewFactory } from "./expired";
import { resetForm } from "./form";
import { resetSuccessPage } from "./success";
import { threeDotLoader } from "../../comp/threeDotLoader";
import { crowdError } from "./crowdError";

export class Page extends HTMLElement {
  constructor(
    username: string,
    requestTime: string,
    hash: string,
    reset: Reset,
    api: Api,
    router: Router
  ) {
    super();

    if (reset.timeReset !== null) {
      this.appendChild(alreadyUsedViewFactory());
      return;
    }

    if (Date.now() > reset.timeExpires) {
      this.appendChild(expiredViewFactory());
      return;
    }

    const form = resetForm(username);
    this.appendChild(form);

    form.addSubmitListener(async (password) => {
      this.innerText = "";
      this.appendChild(
        threeDotLoader("Updating password. This may take a moment.")
      );
      const res = await api.reset.put(username, requestTime, hash, password);
      if (res instanceof BadRequest) {
        this.innerText = "";
        this.appendChild(crowdError(res));
        return;
      }
      if (res instanceof Error) {
        router.error.apiRequestFailed(res);
        return;
      }

      this.innerText = "";
      this.appendChild(resetSuccessPage());
    });
  }
}

customElements.define("reset-page", Page);

type ResetViewPage = (
  username: string,
  resetTime: string,
  hash: string,
  reset: Reset,
  api: Api,
  router: Router
) => Page;
export const resetViewPage: ResetViewPage = (
  username,
  resetTime,
  hash,
  reset,
  api,
  router
) => {
  return new Page(username, resetTime, hash, reset, api, router);
};
