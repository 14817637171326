export type ErrorApiRequestFailedRoute = (error: Error) => Promise<void>;
export type ErrorNoMatchingRoute = (pathname: string) => Promise<void>;

export type RequestCreateRoute = () => Promise<void>;
export type RequestViewRoute = (
  username: string,
  requestTime: string
) => Promise<void>;

export type ResetViewRoute = (
  username: string,
  requestTime: string,
  hash: string
) => Promise<void>;

export type RouteListener = {
  error: {
    apiRequestFailed: ErrorApiRequestFailedRoute;
    noMatch: ErrorNoMatchingRoute;
  };
  request: {
    create: RequestCreateRoute;
    view: RequestViewRoute;
  };
  reset: {
    view: ResetViewRoute;
  };
};

export type AddRouteListener = (listener: RouteListener) => void;

export interface Router extends RouteListener {
  addRouteListener: AddRouteListener;
}

type RouterFactory = () => Router;
export const routerFactory: RouterFactory = () => {
  const listeners: RouteListener[] = [];

  const addRouteListener: AddRouteListener = (listener) =>
    listeners.push(listener);

  const errorNoMatch: ErrorNoMatchingRoute = async (pathname) => {
    history.pushState({}, "", "/error/missing-route");
    listeners.forEach((listener) => listener.error.noMatch(pathname));
  };
  const errorApiRequestFailed: ErrorApiRequestFailedRoute = async (error) => {
    history.pushState({}, "", "/error/api-failure");
    listeners.forEach((listener) => listener.error.apiRequestFailed(error));
  };

  const requestCreateRoute: RequestCreateRoute = async () => {
    history.pushState({}, "", "/");
    listeners.forEach((listener) => listener.request.create());
  };
  const requestViewRoute: RequestViewRoute = async (username, requestTime) => {
    const encodedUsername = encodeURIComponent(username);
    const encodedRequestTime = encodeURIComponent(requestTime);

    const url = `/user/${encodedUsername}/request/${encodedRequestTime}`;
    history.pushState({}, "", url);

    listeners.forEach((listener) =>
      listener.request.view(username, requestTime)
    );
  };

  const resetViewRoute: ResetViewRoute = async (
    username,
    requestTime,
    hash
  ) => {
    const encodedUsername = encodeURIComponent(username);
    const encodedRequestTime = encodeURIComponent(requestTime);

    history.pushState(
      {},
      "",
      `/user/${encodedUsername}/request/${encodedRequestTime}/reset/${hash}`
    );
    listeners.forEach((listener) =>
      listener.reset.view(username, requestTime, hash)
    );
  };

  const route: Router = {
    addRouteListener,
    error: {
      apiRequestFailed: errorApiRequestFailed,
      noMatch: errorNoMatch,
    },
    request: {
      create: requestCreateRoute,
      view: requestViewRoute,
    },
    reset: {
      view: resetViewRoute,
    },
  };

  return route;
};
