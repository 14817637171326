class View extends HTMLElement {
  constructor() {
    super();

    const h1 = document.createElement("h1");
    h1.innerHTML = "Password Request Expired";
    this.appendChild(h1);

    const message = document.createElement("p");
    message.innerHTML =
      "<div>The password reset request link has expired. Please <a href='/'>request a new password reset</a>.</div>";
    this.appendChild(message);
  }
}

customElements.define("reset-page-expired", View);

type ExpiredViewFactory = () => View;
export const expiredViewFactory: ExpiredViewFactory = () => {
  return new View();
};
