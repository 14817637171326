class View extends HTMLElement {
  constructor() {
    super();

    const message = document.createElement("p");
    message.innerText = "The password reset link has already been used.";
    this.appendChild(message);
  }
}

customElements.define("reset-page-already-used", View);

type AlreadyUsedViewFactory = () => View;
export const alreadyUsedViewFactory: AlreadyUsedViewFactory = () => {
  return new View();
};
