import { Api } from "./api.js";
import {
  ErrorApiRequestFailedRoute,
  ErrorNoMatchingRoute,
  RequestCreateRoute,
  RequestViewRoute,
  ResetViewRoute,
  RouteListener,
  Router,
} from "./route.js";

import { noMatchView } from "./page/error/noMatch.js";
import { apiRequestFailedView } from "./page/error/apiReqFailed.js";
import { requestCreatePage } from "./page/request/create/page.js";
import { requestViewPage } from "./page/request/view/page.js";
import { resetViewPage } from "./page/reset/page.js";
import { threeDotLoader } from "./comp/threeDotLoader";

export type AppInit = {
  api: Api;
  body: HTMLElement;
  router: Router;
};

type InitApp = (args: AppInit) => void;

export const InitApp: InitApp = ({ api, body, router }) => {
  const app = document.createElement("div");
  app.classList.add("app");
  body.appendChild(app);

  const errorNoMatchingRoute: ErrorNoMatchingRoute = async (pathname) => {
    app.innerText = "";
    app.appendChild(noMatchView(pathname));
  };

  const errorApiRequestFailedRoute: ErrorApiRequestFailedRoute = async (
    error
  ) => {
    app.innerText = "";
    app.appendChild(apiRequestFailedView(error));
  };

  const requestCreateRoute: RequestCreateRoute = async () => {
    app.innerText = "";
    app.appendChild(requestCreatePage(api, router));
  };

  const requestViewRoute: RequestViewRoute = async (username, requestTime) => {
    app.innerText = "";
    app.appendChild(threeDotLoader("Loading password request data."));
    const request = await api.request.get(username, requestTime);
    if (request instanceof Error) {
      router.error.apiRequestFailed(request);
      return;
    }
    app.innerText = "";
    app.appendChild(requestViewPage(username, request));
  };

  const resetViewRoute: ResetViewRoute = async (
    username,
    requestTime,
    hash
  ) => {
    app.innerText = "";
    app.appendChild(
      threeDotLoader("Validating password reset. Please wait a moment.")
    );
    const reset = await api.reset.get(username, requestTime, hash);
    if (reset instanceof Error) {
      router.error.apiRequestFailed(reset);
      return;
    }

    app.innerText = "";
    app.appendChild(
      resetViewPage(username, requestTime, hash, reset, api, router)
    );
  };

  const listener: RouteListener = {
    error: {
      apiRequestFailed: errorApiRequestFailedRoute,
      noMatch: errorNoMatchingRoute,
    },
    request: {
      create: requestCreateRoute,
      view: requestViewRoute,
    },
    reset: {
      view: resetViewRoute,
    },
  };

  router.addRouteListener(listener);
};
