const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export type Reset = {
  timeExpires: number;
  timeReset: null | number;
};

const validateResetData = (response: unknown): Error | Reset => {
  if (response === null) return new Error("response is null");
  if (typeof response !== "object")
    return new Error(
      `response should be an object; response is a ${typeof response}`
    );

  if (!("timeExpires" in response))
    return new Error(`response is missing timeExpires`);

  const timeExpires = response.timeExpires;

  if (typeof timeExpires !== "number")
    return new Error(`timeExpires is not a number`);

  if (!("timeReset" in response))
    return new Error(`response is missing timeReset`);

  const timeReset = response.timeReset;
  if (!(typeof timeReset === "number" || timeReset === null))
    return new Error(`timeReset is not either null or a number`);

  const result: Reset = {
    timeExpires,
    timeReset,
  };

  return result;
};

type Get = (
  username: string,
  requestTime: string,
  hash: string
) => Promise<Error | Reset>;

type GetFactory = (apiUrl: string) => Get;

const getFactory: GetFactory = (apiUrl) => {
  const get: Get = async (username, requestTime, hash) => {
    const encodedUsername = encodeURIComponent(username);
    const encodedRequestTime = encodeURIComponent(requestTime);

    const url = `${apiUrl}/user/${encodedUsername}/request/${encodedRequestTime}/reset/${hash}`;

    let res: Response;
    try {
      res = await fetch(url, {
        headers,
        method: "GET",
      });
    } catch (error: unknown) {
      if (!(error instanceof Error)) throw error;
      console.error(error);
      throw new Error("Unknown Error");
    }

    if (!res.ok) {
      console.error(res);
      return new Error("res is not ok");
    }

    const data: unknown = await res.json();

    return validateResetData(data);
  };

  return get;
};

type BadRequestBody = {
  reason: string;
  message: string;
};

const validateBadRequestBody = (data: unknown): Error | BadRequestBody => {
  if (typeof data !== "object") return new Error("data is not object");
  if (data === null) return new Error("data is null");

  if (!("reason" in data)) return new Error("data is missing reason");
  const reason = data.reason;
  if (typeof reason !== "string") return new Error("reason is not a string");

  if (!("message" in data)) return new Error("data is missing message");
  const message = data.message;
  if (typeof message !== "string") return new Error("message is not a string");

  const result: BadRequestBody = {
    reason,
    message,
  };

  return result;
};

export class BadRequest extends Error {
  constructor(message: string) {
    super(message);
  }
}

type Put = (
  username: string,
  requestTime: string,
  hash: string,
  password: string
) => Promise<Error | true>;

type PutFactory = (apiUrl: string) => Put;
const put: PutFactory =
  (apiUrl) => async (username, requestTime, hash, password) => {
    const encodedUsername = encodeURIComponent(username);
    const encodedRequestTime = encodeURIComponent(requestTime);

    const url = `${apiUrl}/user/${encodedUsername}/request/${encodedRequestTime}/reset/${hash}`;

    let res: Response;
    try {
      res = await fetch(url, {
        headers,
        method: "PUT",
        body: JSON.stringify({ password }),
      });
    } catch (error: unknown) {
      if (!(error instanceof Error)) throw error;
      console.error(error);
      throw new Error("Unknown Error");
    }

    if (!res.ok) {
      if (res.status === 400) {
        let data: unknown;
        try {
          data = await res.json();
        } catch (error) {
          if (!(error instanceof Error)) throw error;
          console.error(error);
          throw new Error("400 json parse error");
        }
        const body = validateBadRequestBody(data);
        if (body instanceof Error) return body;
        return new BadRequest(body.message);
      }

      console.error(res);
      return new Error("res is not ok");
    }

    return true;
  };

export type ResetResource = {
  get: Get;
  put: Put;
};

type ResetResourceFactory = (apiUrl: string) => ResetResource;
export const resetResourceFactory: ResetResourceFactory = (apiUrl) => {
  const result: ResetResource = {
    get: getFactory(apiUrl),
    put: put(apiUrl),
  };

  return result;
};
