import { Request } from "../../../api/request";

class Page extends HTMLElement {
  constructor(username: string, request: Request) {
    super();

    const { messageId, expires } = request;

    const expirationMinutes = this.calculateExpirationInMinutes(expires);
    if (expirationMinutes < 0) {
      this.renderExpired(messageId);
      return;
    }
    this.renderPending(expirationMinutes, messageId, username);
  }

  calculateExpirationInMinutes(expires: number): number {
    const now = new Date();
    const expiration = new Date(expires);
    const diff = expiration.getTime() - now.getTime();
    return Math.ceil(diff / 1000 / 60);
  }

  renderExpired(messageId: string): void {
    const h1 = document.createElement("h1");
    h1.innerHTML = "Expired Request";
    this.appendChild(h1);

    const main = document.createElement("main");
    this.appendChild(main);

    const info = document.createElement("p");
    info.innerHTML = `<div style='color:red;'>Your password reset request has expired. Please <a href="/">request a new password reset</a>.</div>`;
    main.appendChild(info);

    const hr = document.createElement("hr");
    main.appendChild(hr);

    this.renderSupport(main, messageId);
  }

  renderPending(
    expirationMinutes: number,
    messageId: string,
    username: string
  ): void {
    const h1 = document.createElement("h1");
    h1.innerHTML = "Email Sent";
    this.appendChild(h1);

    const main = document.createElement("main");
    this.appendChild(main);

    const info = document.createElement("p");
    info.innerHTML = `A password reset link has been sent to the email address associated with the account <b>${username}</b>. Click the link in the email to reset your password.`;
    main.appendChild(info);

    const expirationMessage = document.createElement("p");
    if (expirationMinutes < 0) {
      expirationMessage.innerHTML = `<div style='color:red;'>Your password reset request has expired. Please <a href="/">request a new password reset</a>.</div>`;
    } else {
      expirationMessage.innerHTML = `<div style="font-family: 'Open Sans Bold';">Your password reset request will expire in ${expirationMinutes} minutes.</div>`;
    }
    main.appendChild(expirationMessage);

    const hr = document.createElement("hr");
    main.appendChild(hr);

    this.renderSupport(main, messageId);
  }

  renderSupport(main: HTMLElement, messageId: string): void {
    const formattedMessageId = messageId.replace(/</g, "").replace(/>/g, "");

    const support = document.createElement("p");
    support.innerHTML = `<div class="quiet">If you did not receive an email within a few minutes from requesting a password change,
      please contact OCHIN Help Desk and supply the email message ID: 

      <div class="grey-chip">${formattedMessageId}</div>
    </div>`;
    main.appendChild(support);
  }
}

customElements.define("request-view-page", Page);

type RequestViewPage = (username: string, request: Request) => Page;
export const requestViewPage: RequestViewPage = (username, request) => {
  return new Page(username, request);
};
