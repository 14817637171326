import { RequestResource, requestResourceFactory } from "./api/request";
import { ResetResource, resetResourceFactory } from "./api/reset";

export type Api = {
  request: RequestResource;
  reset: ResetResource;
};

type ApiFactory = (apiUrl: string) => Api;
export const apiFactory: ApiFactory = (apiUrl) => {
  const api: Api = {
    request: requestResourceFactory(apiUrl),
    reset: resetResourceFactory(apiUrl),
  };

  return api;
};
