class View extends HTMLElement {
  constructor(pathname: string) {
    super();

    const p = document.createElement("p");
    p.innerText = `No route matching ${pathname}`;
    this.appendChild(p);
  }
}

customElements.define("error-no-match", View);

type NoMatchView = (pathname: string) => View;
export const noMatchView: NoMatchView = (pathname) => {
  return new View(pathname);
};
