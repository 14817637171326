const html = (strings: TemplateStringsArray) => String.raw({ raw: strings });

const template = document.createElement("template");
template.innerHTML = html` <style>
  .complete {
    color: green;
  }
  li {
    color: grey;
  }
</style>`;

export class PasswordRequirements extends HTMLElement {
  #lower: HTMLLIElement;
  #upper: HTMLLIElement;
  #number: HTMLLIElement;
  #special: HTMLLIElement;
  #minimum: HTMLLIElement;
  #noUsername: HTMLLIElement;
  #retype: HTMLLIElement;

  constructor() {
    super();

    this.appendChild(template.content.cloneNode(true));

    const list = document.createElement("ul");
    list.classList.add("no-bullets");
    this.appendChild(list);

    const noUsername = document.createElement("li");
    list.appendChild(noUsername);
    this.#noUsername = noUsername;
    this.noUsername(false);

    const lower = document.createElement("li");
    list.appendChild(lower);
    this.#lower = lower;
    this.lower(false);

    const upper = document.createElement("li");
    list.appendChild(upper);
    this.#upper = upper;
    this.upper(false);

    const number = document.createElement("li");
    list.appendChild(number);
    this.#number = number;
    this.number(false);

    const special = document.createElement("li");
    list.appendChild(special);
    this.#special = special;
    this.special(false);

    const minimum = document.createElement("li");
    list.appendChild(minimum);
    this.#minimum = minimum;
    this.minimum(false);

    const retype = document.createElement("li");
    list.appendChild(retype);
    this.#retype = retype;
    this.retype(false);
  }

  getStatusCharacter(complete: boolean) {
    return complete
      ? "<span style='color: green;'>✔</span>"
      : "<span style='color: red;'>✘</span>";
  }

  noUsername(complete: boolean) {
    this.#noUsername.innerHTML = `${this.getStatusCharacter(
      complete
    )} Password must be not contain username`;
    this.#noUsername.classList.toggle("complete", complete);
  }
  lower(complete: boolean) {
    this.#lower.innerHTML = `${this.getStatusCharacter(
      complete
    )} Password must contain at least one lowercase letter`;
    this.#lower.classList.toggle("complete", complete);
  }
  upper(complete: boolean) {
    this.#upper.innerHTML = `${this.getStatusCharacter(
      complete
    )} Password must contain at least one uppercase letter`;
    this.#upper.classList.toggle("complete", complete);
  }
  number(complete: boolean) {
    this.#number.innerHTML = `${this.getStatusCharacter(
      complete
    )} Password must contain at least one number`;
    this.#number.classList.toggle("complete", complete);
  }
  special(complete: boolean) {
    this.#special.innerHTML = `${this.getStatusCharacter(
      complete
    )} Password must contain at least one special character (!@#$%^&*)`;
    this.#special.classList.toggle("complete", complete);
  }
  minimum(complete: boolean) {
    this.#minimum.innerHTML = `${this.getStatusCharacter(
      complete
    )} Password must be at least 8 characters long`;
    this.#minimum.classList.toggle("complete", complete);
  }
  retype(complete: boolean) {
    this.#retype.innerHTML = `${this.getStatusCharacter(
      complete
    )} Retyped password must match`;
    this.#retype.classList.toggle("complete", complete);
  }
}
