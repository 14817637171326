import { PasswordRequirements } from "./requirements";

export type PasswordFormSubmitListener = (password: string) => void;

const labelFactory = (inputId: string, labelText: string): HTMLLabelElement => {
  const label = document.createElement("label");

  label.setAttribute("for", inputId);
  label.innerText = labelText;

  return label;
};

const passwordInputFactory = (inputId: string): HTMLInputElement => {
  const input = document.createElement("input");

  input.setAttribute("id", inputId);
  input.setAttribute("type", "password");

  return input;
};

customElements.define("o-password-requirements", PasswordRequirements);

class Page extends HTMLElement {
  #listeners: PasswordFormSubmitListener[] = [];

  constructor(username: string) {
    super();

    const h1 = document.createElement("h1");
    h1.innerText = "Password Reset";
    this.appendChild(h1);

    const main = document.createElement("main");
    this.appendChild(main);

    const note = document.createElement("p");
    note.innerText =
      "Note: Password cannot match either your current password or one of your previous 4 passwords. We will not be able to check for this condition until after you have submitted this form.";
    main.appendChild(note);

    const requirements = new PasswordRequirements();
    main.appendChild(requirements);

    const form = document.createElement("form");
    main.appendChild(form);

    const passwordId = "password";
    const passwordLabel = labelFactory(passwordId, "Password");
    const passwordInput = passwordInputFactory(passwordId);
    form.append(passwordLabel, passwordInput);

    const retypeId = "checkPassword";
    const retypeLabel = labelFactory(retypeId, "Retype Password");
    const retypeInput = passwordInputFactory(retypeId);
    form.append(retypeLabel, retypeInput);

    const submitButton = document.createElement("button");
    submitButton.setAttribute("type", "submit");
    submitButton.setAttribute("disabled", "");
    submitButton.innerText = "Update Password";
    form.appendChild(submitButton);

    let meetsRequirements = false;
    const updateRequirements = () => {
      const password = passwordInput.value;
      const retypePassword = retypeInput.value;

      const noUsername = !password.includes(username);
      requirements.noUsername(noUsername);

      const hasLower = /.*[a-z]{1}.*/.test(password);
      requirements.lower(hasLower);

      const hasUpper = /.*[A-Z]{1}.*/.test(password);
      requirements.upper(hasUpper);

      const hasNumber = /.*[0-9]{1}.*/.test(password);
      requirements.number(hasNumber);

      const hasSpecial = /.*[!@#$%^&]{1}.*/.test(password);
      requirements.special(hasSpecial);

      const minimum = password.length >= 8;
      requirements.minimum(minimum);

      const retypeMatches = password !== "" && password === retypePassword;
      requirements.retype(retypeMatches);

      meetsRequirements =
        noUsername &&
        hasLower &&
        hasUpper &&
        hasNumber &&
        hasSpecial &&
        minimum &&
        retypeMatches;

      if (meetsRequirements) {
        submitButton.removeAttribute("disabled");
      } else {
        submitButton.setAttribute("disabled", "");
      }
    };

    passwordInput.onkeyup = () => updateRequirements();
    retypeInput.onkeyup = () => updateRequirements();
    updateRequirements();

    form.onsubmit = (event) => {
      event.preventDefault();

      this.#listeners.forEach((listener) => listener(passwordInput.value));
    };
  }

  addSubmitListener(listener: PasswordFormSubmitListener) {
    this.#listeners.push(listener);
  }

  disconnectedCallback() {
    this.#listeners = [];
  }
}

customElements.define("reset-page-form", Page);

type ResetForm = (username: string) => Page;
export const resetForm: ResetForm = (username) => {
  return new Page(username);
};
