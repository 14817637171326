import { Api } from "../../../api";
import { formFactory } from "./form";
import { threeDotLoader } from "../../../comp/threeDotLoader";
import { Router } from "../../../route";

class Page extends HTMLElement {
  constructor(api: Api, router: Router) {
    super();

    const h1 = document.createElement("h1");
    h1.innerText = "Reset Crowd Password";
    this.appendChild(h1);

    const main = document.createElement("main");
    this.appendChild(main);

    const form = formFactory();
    main.appendChild(form);

    form.addSubmitListener(async (data) => {
      const { username } = data;

      this.innerText = "";
      this.appendChild(
        threeDotLoader(
          "Sending email with password reset link. This may take a moment."
        )
      );

      const request = await api.request.post(data.username);
      if (request instanceof Error) {
        router.error.apiRequestFailed(request);
        return;
      }

      router.request.view(username, request.timeRequest);
    });
  }
}

customElements.define("request-create-view", Page);

type RequestCreatePage = (api: Api, router: Router) => Page;
export const requestCreatePage: RequestCreatePage = (api, router) => {
  return new Page(api, router);
};
