export class Page extends HTMLElement {
  constructor() {
    super();

    const h1 = document.createElement("h1");
    h1.innerText = "Success!";
    this.appendChild(h1);

    const main = document.createElement("main");
    this.appendChild(main);

    const message = document.createElement("p");
    message.innerText = "Your password has been successfully updated.";
    main.appendChild(message);

    const appMessage = document.createElement("p");
    appMessage.innerText = "You may now log into:";
    main.appendChild(message);
  }
}

customElements.define("reset-page-success", Page);

type ResetSuccessPage = () => Page;
export const resetSuccessPage: ResetSuccessPage = () => {
  return new Page();
};
