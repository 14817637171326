class Comp extends HTMLElement {
  constructor(message: string) {
    super();

    // Loader code from https://loading.io/css/
    const content = document.createElement("p");
    content.innerHTML = `
      <div>${message}</div> 
      <center>
        <div class="three-dot-loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </center>`;
    this.appendChild(content);
  }
}

customElements.define("three-dot-loader", Comp);

type ThreeDotLoader = (message: string) => Comp;
export const threeDotLoader: ThreeDotLoader = (message) => {
  return new Comp(message);
};
