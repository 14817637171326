const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export type Request = {
  expires: number;
  messageId: string;
  timeRequest: string;
};

const validateRequest = (request: unknown): Error | Request => {
  if (request === null) return new Error("request is null");
  if (typeof request !== "object")
    return new Error(
      `request should be an object. request is a ${typeof request}.`
    );

  if (!("expires" in request)) return new Error("request.expires is missing");

  const expires = request.expires;

  if (typeof expires !== "number")
    return new Error("request.expires is not a number");

  if (!("messageId" in request))
    return new Error("request.messageId is missing");

  const messageId = request.messageId;

  if (typeof messageId !== "string")
    return new Error(`request.messageId is not a string`);

  if (!("timeRequest" in request))
    return new Error("request.timeRequest is missing");

  const timeRequest = request.timeRequest;

  if (typeof timeRequest !== "string")
    return new Error("request.timeRequest is not a string");

  const result: Request = {
    expires,
    messageId,
    timeRequest,
  };

  return result;
};

type Get = (username: string, requestTime: string) => Promise<Error | Request>;
type GetFactory = (apiUrl: string) => Get;
const getFactory: GetFactory = (apiUrl) => {
  const get: Get = async (username, requestTime) => {
    const encodedUsername = encodeURIComponent(username);
    const encodedRequestTime = encodeURIComponent(requestTime);

    const url = `${apiUrl}/user/${encodedUsername}/request/${encodedRequestTime}`;
    let res: Response;
    try {
      res = await fetch(url, {
        headers,
        method: "GET",
      });
    } catch (error: unknown) {
      console.error(error);
      if (!(error instanceof Error)) throw error;
      return error;
    }

    if (!res.ok) {
      console.error(res);
      return new Error("response is not ok");
    }

    const data: unknown = await res.json();

    return validateRequest(data);
  };

  return get;
};

type NotFoundRes = {
  message: string;
};

const validateNotFoundRes = (data: unknown): Error | NotFoundRes => {
  if (data === null) return new Error("data is null");
  if (typeof data !== "object") return new Error("data is not an object");

  if (!("message" in data)) return new Error("data is missing message");
  const message = data.message;
  if (typeof message !== "string") return new Error("message is not a string");

  const result: NotFoundRes = {
    message,
  };

  return result;
};

type PostResBody = {
  timeRequest: string;
};
const validatePostResBody = (body: unknown): Error | PostResBody => {
  if (body === null) return new Error("body is null");
  if (typeof body !== "object")
    return new Error(`body should be an object. body is a ${typeof body}.`);

  if (!("timeRequest" in body)) return new Error("body.timeRequest is missing");

  const timeRequest = body.timeRequest;

  if (typeof timeRequest !== "string")
    return new Error("body.timeRequest is not a string");

  const result: PostResBody = { timeRequest };

  return result;
};

type Post = (username: string) => Promise<Error | PostResBody>;

type PostFactory = (apiUrl: string) => Post;
const postFactory: PostFactory = (apiUrl) => async (username) => {
  const encodedUsername = encodeURIComponent(username);
  let res: Response;
  try {
    res = await fetch(`${apiUrl}/user/${encodedUsername}/request`, {
      headers,
      method: "POST",
    });
  } catch (error: unknown) {
    console.error(error);
    if (!(error instanceof Error)) throw error;
    return error;
  }

  if (!res.ok) {
    if (res.status === 404) {
      let data: unknown;
      try {
        data = await res.json();
      } catch (error) {
        if (error instanceof Error)
          return new Error("unable to parse 404 response");
        throw error;
      }

      const notFoundRes = validateNotFoundRes(data);
      if (notFoundRes instanceof Error) {
        console.error(notFoundRes);
        return new Error("unable to validate 404 response");
      }
      return new Error(notFoundRes.message);
    }

    console.error(res);
    return new Error("response is not ok");
  }

  const data: unknown = await res.json();

  return validatePostResBody(data);
};

export type RequestResource = {
  get: Get;
  post: Post;
};

type RequestResourceFactory = (apiUrl: string) => RequestResource;
export const requestResourceFactory: RequestResourceFactory = (apiUrl) => {
  const result: RequestResource = {
    get: getFactory(apiUrl),
    post: postFactory(apiUrl),
  };

  return result;
};
