const html = (strings: TemplateStringsArray) => String.raw({ raw: strings });

const template = document.createElement("template");
template.innerHTML = html`
  <header>
    <a class="logo" href="/"
      ><img src="/image/ochin-logo-reverse.png" /><span>Web Tools</span></a
    >
  </header>
`;

export class Header extends HTMLElement {
  constructor() {
    super();

    this.appendChild(template.content.cloneNode(true));
  }
}
