import { BadRequest } from "../../api/reset";

class Page extends HTMLElement {
  constructor(badRequest: BadRequest) {
    super();

    const main = document.createElement("main");
    this.appendChild(main);

    const h1 = document.createElement("h1");
    h1.innerHTML = "Crowd Error";
    main.appendChild(h1);

    const message = document.createElement("p");
    message.innerText = badRequest.message;
    main.appendChild(message);

    const button = document.createElement("button");
    button.innerText = "Back";
    button.onclick = () => history.back();
    main.appendChild(button);
  }
}

customElements.define("reset-page-crowd-error", Page);

type CrowdError = (badRequest: BadRequest) => Page;
export const crowdError: CrowdError = (badRequest) => {
  return new Page(badRequest);
};
