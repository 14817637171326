import { apiFactory } from "./api";
import { AppInit, InitApp } from "./app";
import { Header } from "./comp/header";
import { routerFactory } from "./route";

customElements.define("o-header", Header);

declare var API_URL: string | undefined;
if (typeof API_URL === "undefined") throw new Error("API_URL is undefined");

const api = apiFactory(API_URL);
const router = routerFactory();

const body = document.querySelector("body");
if (body === null) throw new Error("body is null");

const header = new Header();
body.appendChild(header);

const appInit: AppInit = {
  api,
  body,
  router,
};
InitApp(appInit);

type InitRoute = (pathname: string) => void;
const initRoute: InitRoute = (pathname) => {
  if (pathname === "/") {
    router.request.create();
    return;
  }

  const requestViewRegEx = /^\/user\/([^\/]+)\/request\/([^\/]+)$/;
  if (requestViewRegEx.test(pathname)) {
    const match = pathname.match(requestViewRegEx);
    if (match === null) {
      router.error.noMatch(pathname);
      return;
    }

    const username = decodeURIComponent(match[1]);
    const requestTime = decodeURIComponent(match[2]);
    router.request.view(username, requestTime);
    return;
  }

  const resetViewRegEx =
    /^\/user\/([^\/]+)\/request\/([^\/]+)\/reset\/([^\/]+)$/;
  if (resetViewRegEx.test(pathname)) {
    const match = pathname.match(resetViewRegEx);
    if (match === null) {
      router.error.noMatch(pathname);
      return;
    }

    const username = decodeURIComponent(match[1]);
    const requestTime = decodeURIComponent(match[2]);
    const hash = match[3];
    router.reset.view(username, requestTime, hash);
    return;
  }

  const apiFailureRegEx = /^\/error\/api-failure$/;
  if (apiFailureRegEx.test(pathname)) {
    const match = pathname.match(apiFailureRegEx);
    if (match === null) {
      router.error.noMatch(pathname);
      return;
    }

    router.error.apiRequestFailed(
      new Error("(Page was refreshed and error data was lost.)")
    );
    return;
  }

  router.error.noMatch(pathname);
};

const init = async () => initRoute(location.pathname);
window.addEventListener("popstate", async () => initRoute(location.pathname));

init();
