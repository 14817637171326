type FormData = {
  username: string;
};

type Listener = (data: FormData) => Promise<void>;

class Form extends HTMLElement {
  #listeners: Listener[] = [];

  constructor() {
    super();

    let disableButton = true;
    const form = document.createElement("form");
    this.appendChild(form);

    const usernameFieldset = document.createElement("fieldset");
    form.appendChild(usernameFieldset);

    const usernameId = "username";
    const usernameLabel = document.createElement("label");
    usernameLabel.setAttribute("for", usernameId);
    usernameLabel.innerText = "Crowd Username";
    usernameFieldset.appendChild(usernameLabel);

    const username = document.createElement("input");
    username.setAttribute("id", usernameId);
    username.setAttribute("type", "text");
    username.setAttribute("autocomplete", "username");
    username.setAttribute("required", "");
    username.setAttribute("placeholder", "Typically your email address");
    usernameFieldset.appendChild(username);

    const submitButton = document.createElement("button");
    submitButton.setAttribute("type", "submit");
    submitButton.setAttribute("disabled", `${disableButton}`);
    submitButton.innerText = "Submit";
    form.appendChild(submitButton);

    username.onkeyup = () => {
      disableButton = !form.checkValidity();
      disableButton
        ? submitButton.setAttribute("disabled", "true")
        : submitButton.removeAttribute("disabled");
    };

    form.onsubmit = (event) => {
      event.preventDefault();

      const data: FormData = {
        username: username.value,
      };

      this.#listeners.forEach((listener) => listener(data));
    };
  }

  addSubmitListener(listener: Listener) {
    this.#listeners.push(listener);
  }
}

customElements.define("request-reset-form", Form);

type FormFactory = () => Form;
export const formFactory: FormFactory = () => {
  return new Form();
};
