export class View extends HTMLElement {
  constructor(error: Error) {
    super();

    const h1 = document.createElement("h1");
    h1.innerHTML = "Request Error";
    this.appendChild(h1);

    const message = document.createElement("p");
    message.innerText = "API request failed for the following reason:";
    this.appendChild(message);

    const errorMessage = document.createElement("p");
    errorMessage.innerText = error.message;
    this.appendChild(errorMessage);
  }
}

customElements.define("error-api-request-failed", View);

type ApiRequestFailedView = (error: Error) => View;
export const apiRequestFailedView: ApiRequestFailedView = (error) => {
  return new View(error);
};
